import shopifyGenericDefaults from '../shopify-dawn-generic/config.js';
import ConvermaxProductForm from './product-form.js';

const InitFunc = () => {
  shopifyGenericDefaults.InitFunc?.();
  window.customElements.define('convermax-product-form', ConvermaxProductForm);
};

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  product: {
    ...shopifyGenericDefaults.product,
    noImageSrc: '/cdn/shop/t/23/assets/no-img.jpg',
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    isAutoRedirectDisabled: true,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) =>
        ![
          'SearchBox',
          'SearchBoxDialogButton',
          'VehicleSpecs',
          'SearchVehicleWidget',
          'FacetDialog',
          'SearchHeader',
        ].includes(w.name),
    ),
    {
      ...shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox'),
      name: 'SearchBoxDialogButton',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm-home',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 550,
    },
    {
      name: 'TotalHits',
      type: 'SearchHeader',
      location: '#ProductCountDesktop',
      template: 'main/totalHits',
    },
    {
      name: 'CustomFacetDialog',
      type: 'ContextDialog',
      location: { class: 'FacetDialog' },
      template: 'CustomFacetDialog',
    },
    {
      name: 'CustomFacetDialogButton',
      type: 'DialogButton',
      dialogName: 'CustomFacetDialog',
    },
    {
      name: 'CustomFacetDialogFacetPanel',
      type: 'FacetPanel',
      ignoreFields: shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetDialog').ignoreFields,
    },
    {
      name: 'CustomFacetDialogVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
      sortSelectClass: 'select__select caption-large',
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: ['Year', 'Make', 'Model'],
      },
    },
  ],
};
