
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('li', { 'className': 'grid__item scroll-trigger animate--slide-in' }, _createElement('div', { 'className': 'card-wrapper product-card-wrapper' }, _createElement('div', { 'className': 'card card--standard card--media' }, _createElement('div', { 'className': 'card__inner color-scheme-62ff07bf-9fa9-41b8-b3ff-01c2885e6d39 gradient ratio' }, _createElement('div', { 'className': 'card__media' }, _createElement('a', {
                    'href': this.url,
                    'className': 'media media--transparent media--hover-effect'
                }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.sku),
                    'className': 'motion-reduce',
                    'loading': 'lazy',
                    'width': '900',
                    'height': '900'
                }))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('h3', { 'className': 'card__heading' }, _createElement('a', {
                    'href': this.url,
                    'id': 'StandardCardNoMediaLink-template--cm-template__product-grid-' + this.id,
                    'className': 'full-unstyled-link',
                    'aria-labelledby': 'StandardCardNoMediaLink-template--cm-template__product-grid-' + this.id + ' NoMediaStandardBadge-template--cm-template__product-grid-' + this.id
                }, '\n                ', this.removeHTML(this.title), '\n              '))), _createElement('div', { 'className': 'card__badge bottom left' })), !this.out_of_stock ? _createElement('div', {
                    'className': 'quick-add no-js-hidden',
                    'key': '1158'
                }, _createElement('convermax-product-form', { 'data-section-id': 'template--cm-template__product-grid' }, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'quick-add-template--cm-template__product-' + this.id,
                    'acceptCharset': 'UTF-8',
                    'className': 'form',
                    'encType': 'multipart/form-data',
                    'noValidate': 'novalidate',
                    'data-type': 'add-to-cart-form'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0],
                    'className': 'product-variant-id'
                }), _createElement('button', {
                    'id': 'quick-add-template--cm-template__product-grid' + this.id + '-submit',
                    'type': 'submit',
                    'name': 'add',
                    'className': 'quick-add__submit button button--full-width button--secondary',
                    'aria-haspopup': 'dialog',
                    'aria-labelledby': 'quick-add-template--cm-template__product-grid' + this.id + '-submit title-template--cm-template__product-grid-' + this.id,
                    'aria-live': 'polite',
                    'data-sold-out-message': 'true'
                }, _createElement('span', {}, 'Add to cart '), _createElement('span', { 'className': 'sold-out-message hidden' }, ' Sold out '), _createElement('div', { 'className': 'loading__spinner hidden' }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'className': 'spinner',
                    'viewBox': '0 0 66 66',
                    'xmlns': 'http://www.w3.org/2000/svg'
                }, _createElement('circle', {
                    'className': 'path',
                    'fill': 'none',
                    'strokeWidth': '6',
                    'cx': '33',
                    'cy': '33',
                    'r': '30'
                })))), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'section-id',
                    'value': 'template--cm-template__product-grid'
                })))) : null), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('div', { 'className': 'product-sku' }, 'SKU: ', this.removeHTML(this.sku)), _createElement('h3', {
                    'className': 'card__heading h5',
                    'id': 'title-template--cm-template__product-grid-' + this.id
                }, _createElement('a', {
                    'href': this.url,
                    'id': 'CardLink-template--cm-template__product-grid-' + this.id,
                    'className': 'full-unstyled-link',
                    'aria-labelledby': 'CardLink-template--cm-template__product-grid-' + this.id + ' Badge-template--cm-template__product-grid-' + this.id
                }, '\n              ', this.removeHTML(this.title), '\n            ')), _createElement('div', { 'className': 'card-information' }, _createElement('span', { 'className': 'caption-large light' }), _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__container' }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '3656'
                }, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Regular price'), _createElement('span', { 'className': 'price-item price-item--regular' }, ' ', this.formatPrice(this.price), ' ')) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '3942'
                }, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Regular price'), _createElement('span', {}, _createElement('s', { 'className': 'price-item price-item--regular' }, '\n                      ', this.formatPrice(this.compare_at_price), '\n                    ')), _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Sale price'), _createElement('span', { 'className': 'price-item price-item--sale price-item--last' }, '\n                    ', this.formatPrice(this.price), '\n                  ')) : null))))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []