
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function totalHitsRT () {
    return _createElement('span', {}, this.selectedVehicle ? _createElement('span', {
        'className': 'cm_total-hits__vehicle',
        'key': '9'
    }, '\n    Showing ', this.totalHits, ' products for ', this.selectedVehicle, '\n  ') : null, !this.selectedVehicle ? _createElement('span', {
        'className': 'cm_total-hits__vehicle',
        'key': '153'
    }, this.totalHits, ' products') : null);
}
        export const componentNames = []